import React from 'react';
import QueueAnim from 'rc-queue-anim';
import TweenOne from 'rc-tween-one';
import OverPack from 'rc-scroll-anim/lib/ScrollOverPack';
import { getChildrenToRender } from './utils';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';

class Content4 extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      selectedImage: null,
    };
  }

  getDelay = (e, b) => (e % b) * 100 + Math.floor(e / b) * 100 + b * 100;

  handleImageClick = (index) => {
    this.setState({ selectedImage: index });
  }

  handleCloseImage = () => {
    this.setState({ selectedImage: null });
  }

  render() {
    const { ...props } = this.props;
    const { dataSource } = props;
    delete props.dataSource;
    delete props.isMobile;

    const images = dataSource.images;
    const { selectedImage } = this.state;

    return (
      <div {...props} {...dataSource.wrapper}>
        <div {...dataSource.page}>
          <div {...dataSource.titleWrapper}>
            {dataSource.titleWrapper.children.map(getChildrenToRender)}
          </div>
          <OverPack {...dataSource.OverPack}>
            <QueueAnim 
              key="queue"
              type="bottom"
              style={{ 
                display: 'flex', 
                justifyContent: 'space-between', 
                marginTop: '20px' 
              }}
            >
              {images.map((image, index) => (
                <TweenOne
                  key={index}
                  animation={{
                    opacity: 0,
                    type: 'from',
                    ease: 'easeOutQuad',
                    delay: this.getDelay(index, 3),
                  }}
                  style={{ 
                    borderRadius: '10px',
                    overflow: 'hidden',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center'
                  }}
                >
                  <div
                    onMouseEnter={(e) => {
                      e.currentTarget.style.transform = 'scale(1.1)';
                      e.currentTarget.querySelector('img').style.objectPosition = 'bottom';
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.transform = 'scale(1)';
                      e.currentTarget.querySelector('img').style.objectPosition = 'top';
                    }}
                    onClick={() => this.handleImageClick(index)}
                    style={{ transition: 'transform 0.5s ease', cursor: 'pointer' }}
                  >
                    <img 
                      src={image.src} 
                      alt={image.alt}
                      style={{ 
                        width: '450px', 
                        height: '450px', 
                        objectFit: 'cover', 
                        objectPosition: 'top',
                        transition: 'object-position 20s ease',
                        borderRadius: '10px'
                      }} 
                    />
                  </div>
                  <span style={{ marginTop: '30px', textAlign: 'center' }}>{image.title}</span>
                </TweenOne>
              ))}
            </QueueAnim>
          </OverPack>
        </div>
        <Modal
          visible={selectedImage !== null}
          onCancel={this.handleCloseImage}
          footer={null}
          width="fit-content"
          bodyStyle={{ padding: 0 }}
          closeIcon={<CloseOutlined style={{ fontSize: '24px', color: '#fff' }} />}
          style={{ top: 20 }}
        >
          {selectedImage !== null && (
            <img
              src={images[selectedImage].src}
              alt={images[selectedImage].alt}
              style={{ maxWidth: '100%' }}
            />
          )}
        </Modal>
      </div>
    );
  }
}

export default Content4;